import { version } from "../../package.json";

export const env = {
  APP_VERSION: version,
  STORE_API_URL: 'https://api.redburger.es/api/v3/store',
  AUTH_API_URL: 'https://redburger.es',
  MENU_API_URL: 'https://api.redburger.es/v1/menu',
};

export const firebaseConfig = {
  apiKey: "AIzaSyDLze42n1CZCm3L8-15yYWw-7-2qOGzuHs",
  authDomain: "redburgerapp.firebaseapp.com",
  databaseURL: "https://redburgerapp.firebaseio.com",
  projectId: "redburgerapp",
  storageBucket: "redburgerapp.appspot.com",
  messagingSenderId: "877375988448",
  appId: "1:877375988448:web:6efe40bc189f4bf5dc2685",
  measurementId: "G-Y3N8C07WZ4"
};
