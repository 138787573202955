import axios from "axios";
import { env } from "@/config/config";
import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators'
import { CustomerDataType } from "@/interfaces/types";
import { getStoreCustomer } from "@/services/Api";
import { StoreAuthStateAuthType } from "@/interfaces/Store";
import { store } from '@/store';
import { initialUnencryptedStorage } from '@/store/storage';

@Module({
  dynamic: true,
  store,
  name: 'auth',
  preserveState: Boolean(initialUnencryptedStorage['auth'])
})
class Auth extends VuexModule {
  userId = 0
  customer?: CustomerDataType = undefined
  auth?: StoreAuthStateAuthType = undefined

  get loggedUserId() {
    return this.userId
  }

  get isAuthenticated() {
    return !!this.userId
  }

  get userAuth() {
    return this.auth
  }

  get accessToken() {
    return this.auth?.access_token
  }

  get refreshToken() {
    return this.auth?.refresh_token
  }

  get customerData() {
    return this.customer
  }

  @Mutation
  setUserId(id: number) {
    this.userId = id;
  }

  @Mutation
  setCustomerData(customer: CustomerDataType) {
    this.customer = customer;
  }

  @Mutation
  setUserAuthData(data: any) {
    this.auth = data;
  }

  @Mutation
  clearAuthentication() {
    this.userId = 0;
    this.auth = undefined;
    this.customer = undefined;
  }

  @Action({commit: 'clearAuthentication'})
  clearAuthData() {
    return undefined;
  }

  @Action({rawError: true})
  getCustomerData() {
    return new Promise<void>((resolve, reject) => {
      getStoreCustomer(this.userId).then((customerData: CustomerDataType) => {
        this.context.commit('setCustomerData', customerData);
        resolve();
      }).catch((err) => {
        reject(err.response.data.error)
      });
    })
  }


  @Action({rawError: true})
  loginUser(data: { username: string; password: string }) {
    return new Promise<void>((resolve, reject) => {
      axios.request({
        baseURL: env.AUTH_API_URL,
        url: "/oauth/token",
        method: "post",
        auth: {
          username: process.env.VUE_APP_OAUTH_CLIENT_ID,
          password: process.env.VUE_APP_OAUTH_CLIENT_SECRET
        },
        data: {
          username: data.username,
          password: data.password,
          "grant_type": "password",
          "scope": "basic",
        }
      }).then((res) => {

        // Save the Oauth2 data into the Vuex store (access_token, refresh_token...)
        this.context.commit('setUserAuthData', res.data);

        // Obtain JWT Token "sub" (userId) from the token payload
        const accessToken = res.data.access_token;
        const accessTokenB64 = accessToken.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
        const tokenJsonPayload = JSON.parse(decodeURIComponent(atob(accessTokenB64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')));
        const tokenUserId = +tokenJsonPayload.sub; // unary (+) operator casts the string to a number

        // If tokenUserId exists, store the retrieved userID and resolve the root Promise
        if (tokenUserId) {
          this.context.commit('setUserId', tokenUserId);
          resolve()
        } else {
          // If no tokenUserId exists, remove any authentication data and reject the root Promise as sign-in has failed
          this.context.commit('clearAuthentication');
          reject("auth.unexpected_error")
        }
      }).catch((err) => {
        // Remove any authentication and reject the root Promise as sign-in has failed
        this.context.commit('clearAuthentication');
        reject(`auth.${err.response.data.error}`)
      })
    })
  }

  @Action({commit: 'clearAuthentication', rawError: true})
  doRefreshToken() {
    return new Promise((resolve, reject) => {
      axios.request({
        baseURL: env.AUTH_API_URL,
        url: "/oauth/token",
        method: "post",
        data: {
          "client_id": process.env.VUE_APP_OAUTH_CLIENT_ID,
          "client_secret": process.env.VUE_APP_OAUTH_CLIENT_SECRET,
          "refresh_token": this.refreshToken,
          "grant_type": "refresh_token",
        }
      }).then((res) => {
        // Save the Oauth2 data into the Vuex store (access_token, refresh_token...)
        this.context.commit('setUserAuthData', res.data);
        resolve(res.data.access_token)
      }).catch((err) => {
        reject(err.response.data.error)
      })
    })
  }

  @Action({commit: 'clearAuthentication', rawError: true})
  doLogoutUser() {
    return new Promise<void>((resolve, reject) => {
      axios.request({
        baseURL: env.AUTH_API_URL,
        url: "/oauth/revoke",
        method: "post",
        withCredentials: true,
        data: {
          "token": this.accessToken,
          "client_id": process.env.VUE_APP_OAUTH_CLIENT_ID,
          "client_secret": process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        }
      }).then(() => {
        // Remove any authentication and resolve the root Promise as sign-out has completed
        this.context.commit('clearAuthentication');
        resolve()
      }).catch((err) => {
        this.context.commit('clearAuthentication');
        reject(err.response.data.error)
      })
    })
  }

}

export default getModule(Auth);
