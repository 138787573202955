/* eslint-disable @typescript-eslint/camelcase */
import { env } from "@/config/config";
// import { OrderType } from './types'
import { CouponType, CustomerDataType } from "@/interfaces/types";
import { AxiosResponse } from "axios";

import { default as ApiLibrary } from './ApiLibrary.mjs';
const StoreApi = new ApiLibrary({url: env.STORE_API_URL});
const MenuApi = new ApiLibrary({url: env.MENU_API_URL});

export const getStoreCustomer = (id: number) => {
  return StoreApi.get(`customers/${id}`).then((result: { data: CustomerDataType }) => result.data);
}

export const getStoreOrders = (customer: number, page = 1) => {
  return StoreApi.get('orders', {
    customer,
    page,
  }, true).then((result: AxiosResponse) => {
    return {orders: result.data, totalPages: +result.headers['x-wp-totalpages']}
  });
}

export const getStoreCategoryProducts = (category: number) => {
  return StoreApi.get('products', {
    category,
    order: 'asc',
    per_page: 50,
  }).then((result: { data: any }) => result.data);
}

export const getStoreProduct = (id: number) => {
  return StoreApi.get(`products/${id}`).then((result: { data: any }) => result.data);
};

export const getStoreCoupon = async (code: string) => {
  const result: { data: CouponType } = await StoreApi.get(`coupon/${code}`);
  return result.data;
};

export const getStoreProductBySlug = (slug: string) => {
  return StoreApi.get('products', {
    slug,
    order: 'asc',
    per_page: 1,
  }).then((result: { data: any }) => result.data);
};

export const getStoreProducts = (include: number[] | string[]) => {
  return StoreApi.get('products', {
    include,
    order: 'asc',
    per_page: 50,
  }).then((result: { data: any }) => result.data);
}

export const searchStoreProducts = async (search: string) => {
  const result: { data: any } = await StoreApi.get('products', {search, per_page: 20});
  return result.data;
};

export const getStoreFeaturedProducts = () => {
  return StoreApi.get('products', {
    featured: true,
    order: 'asc',
    per_page: 50,
  }).then((result: { data: any }) => result.data);
}

export const getMenuDaily = () => {
  return MenuApi.get('dia').then((result: { data: any }) => result.data);
}

export const createOrder = () => {
  // const orderData: OrderType = {}
  // return orderData;
}
